// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-index-tsx": () => import("./../../../src/pages/404/index.tsx" /* webpackChunkName: "component---src-pages-404-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-search-index-tsx": () => import("./../../../src/pages/search/index.tsx" /* webpackChunkName: "component---src-pages-search-index-tsx" */),
  "component---src-templates-article-index-tsx": () => import("./../../../src/templates/article/index.tsx" /* webpackChunkName: "component---src-templates-article-index-tsx" */),
  "component---src-templates-category-index-tsx": () => import("./../../../src/templates/category/index.tsx" /* webpackChunkName: "component---src-templates-category-index-tsx" */),
  "component---src-templates-segment-index-tsx": () => import("./../../../src/templates/segment/index.tsx" /* webpackChunkName: "component---src-templates-segment-index-tsx" */),
  "component---src-templates-subcategory-index-tsx": () => import("./../../../src/templates/subcategory/index.tsx" /* webpackChunkName: "component---src-templates-subcategory-index-tsx" */)
}

