module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"google":{"families":["Sora:300,400,600,700","Inter:300,400,600,700"]}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Blog do Inter","short_name":"Blog do Inter","start_url":"/","background_color":"#e5e5e5","theme_color":"#EB7000","display":"minimal-ui","icon":"./src/assets/images/favicon.ico","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"855de8c8e3279e4a0583ea56554ff9ce"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
